<template>
  <div>
    Sisend:

    <v-data-table
      :headers="headers"
      :items="items"
      :hide-default-footer=true
    >
      <template v-slot:item.insurer="{ item }">
        <img :src="'assets/images/insurer-logos/' + item.insurer + '.png'" width="80">
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="pa-3">
          <v-btn light depressed @click="openLog(item)" color="#5779ce91">Vaata logi</v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  export default {
    name: "Quotes",
    props: ['quotes', 'offer_id'],
    data () {
      return {
        headers: [
          {
            text: "",
            align: "start",
            sortable: false,
            value: 'insurer'
          },
          {
            text: "1 kuu",
            value: "month1"
          },
          {
            text: "2 kuud",
            value: "month2"
          },
          {
            text: "3 kuud",
            value: "month3"
          },
          {
            text: "4 kuud",
            value: "month4"
          },
          {
            text: "5 kuud",
            value: "month5"
          },
          {
            text: "6 kuud",
            value: "month6"
          },
          {
            text: "7 kuud",
            value: "month7"
          },
          {
            text: "8 kuud",
            value: "month8"
          },
          {
            text: "9 kuud",
            value: "month9"
          },
          {
            text: "10 kuud",
            value: "month10"
          },
          {
            text: "11 kuud",
            value: "month11"
          },
          {
            text: "12 kuud",
            value: "month12"
          },
          { text: '', value: 'actions', sortable: false, align: "right"},
        ],
        items: []
      }
    },
    mounted () {
      for (let quote_key in this.quotes) {
        let item = this.quotes[quote_key];
        for (let price_key in this.quotes[quote_key]["prices"]) {
          let name  = "month" + this.quotes[quote_key]["prices"][price_key]["period"];
          item[name] = this.quotes[quote_key]["prices"][price_key]["amount"] + " €";
        }
        this.items.push(item);
      }
    },
    methods: {
      openLog: function (item) {
        this.$emit('open_log_dialog', item.insurer, this.offer_id)
      }
    }
  }
</script>