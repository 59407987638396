<template>
  <div class="text-center pagination-container">
    <v-container>
      <v-row justify="center">
        <v-col cols="8">
          <v-container class="max-width">
            <v-pagination
                v-model="page"
                :length="pagination"
                :total-visible="7"
                v-on:input="logPage"
            ></v-pagination>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: ['pagination', 'page'],
  methods: {
    logPage: function (page) {
      this.$emit("load_new_page", page);
    }
  }
}
</script>

<style scoped>

</style>