<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      scrollable
      width="1200"
    >
      <v-card>
        <v-card-title class="text-h5 ">
          Päringu logid
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 500px;">
          <template v-for="log in this.logs">
           <div>
              <template v-if="log.reference == 'offer_response'">
                <h3 class="mt-2">Päringu vastuse seltsist</h3>
              </template>
              <template v-if="log.reference == 'offer_request'">
                <h3 class="mt-2">Päring seltsi</h3>
              </template>
              {{log.date}}
              <br>
              <p>{{log.content}}</p>
           </div>
          </template>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="closeDialog"
          >
            Sulge
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: "LogDialog",
    props: ["dialog", "logs"],
    methods: {
      closeDialog: function () {
        this.$emit('close_log_dialog')
      }
    }
  }
</script>